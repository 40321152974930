var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"card",staticClass:"pt-12 grey lighten-3",staticStyle:{"height":"calc(100% - 50px)","overflow":"auto"}},[_c('v-card',{staticClass:"ma-4 px-4 py-4"},[_c('v-sheet',{staticClass:"px-4 py-2 v-card-material mb-n6 rounded",attrs:{"color":"primary","width":"100%","elevation":"6","dark":""}},[_c('div',{staticClass:"v-card-material-title pt-1"},[_vm._v(" 腹膜透析(CAPD/APD)每日录入 ")]),_c('div',{staticClass:"v-card-material-subtitle py-1"},[_vm._v(" 腹透远程系统 ")])]),_c('v-form',{ref:"form",model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-row',{attrs:{"no-gutters":"","dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-chip',{staticClass:"elevation-2",attrs:{"color":"primary","label":"","text-color":"white"}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-label ")]),_vm._v(" "+_vm._s(_vm.baseInfo.ISCAPD)+" ")],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-radio-group',{staticClass:"mt-4",attrs:{"row":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',{staticClass:"m_black"},[_vm._v("每日录入")])]},proxy:true}]),model:{value:(_vm.baseInfo.ISCAPD),callback:function ($$v) {_vm.$set(_vm.baseInfo, "ISCAPD", $$v)},expression:"baseInfo.ISCAPD"}},[_c('v-radio',{attrs:{"label":"CAPD","value":"CAPD"}}),_c('v-radio',{attrs:{"label":"APD","value":"APD"}})],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{ref:"WEIGHT",attrs:{"label":"体重(Kg)","rules":[function (v) { return !!v || _vm.rule('WEIGHT') || '请填写体重(Kg)！'; }],"type":"number"},model:{value:(_vm.baseInfo.WEIGHT),callback:function ($$v) {_vm.$set(_vm.baseInfo, "WEIGHT", $$v)},expression:"baseInfo.WEIGHT"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{ref:"BLOODPRESSURE",attrs:{"label":"收缩压(mmHg)","rules":[
              function (v) { return !!v || _vm.rule('BLOODPRESSURE') || '请填写收缩压(mmHg)！'; } ],"type":"number"},model:{value:(_vm.baseInfo.BLOODPRESSURE),callback:function ($$v) {_vm.$set(_vm.baseInfo, "BLOODPRESSURE", $$v)},expression:"baseInfo.BLOODPRESSURE"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{ref:"DIASTOLICPRESSURE",attrs:{"label":"舒张压(mmHg)","rules":[
              function (v) { return !!v || _vm.rule('DIASTOLICPRESSURE') || '请填写舒张压(mmHg)！'; } ],"type":"number"},model:{value:(_vm.baseInfo.DIASTOLICPRESSURE),callback:function ($$v) {_vm.$set(_vm.baseInfo, "DIASTOLICPRESSURE", $$v)},expression:"baseInfo.DIASTOLICPRESSURE"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{ref:"CAPD15NUMBERBAGS",attrs:{"label":"1.5%腹透液袋数","rules":[
              function (v) { return !!v || _vm.rule('CAPD15NUMBERBAGS') || '请填写1.5%腹透液袋数！'; } ],"type":"number"},model:{value:(_vm.baseInfo.CAPD15NUMBERBAGS),callback:function ($$v) {_vm.$set(_vm.baseInfo, "CAPD15NUMBERBAGS", $$v)},expression:"baseInfo.CAPD15NUMBERBAGS"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{ref:"CAPD25NUMBERBAGS",attrs:{"label":"2.5%腹透液袋数","rules":[
              function (v) { return !!v || _vm.rule('CAPD25NUMBERBAGS') || '请填写2.5%腹透液袋数！'; } ],"type":"number"},model:{value:(_vm.baseInfo.CAPD25NUMBERBAGS),callback:function ($$v) {_vm.$set(_vm.baseInfo, "CAPD25NUMBERBAGS", $$v)},expression:"baseInfo.CAPD25NUMBERBAGS"}})],1),(_vm.baseInfo.ISCAPD === 'CAPD')?_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{ref:"FILTRATIONCAPACITY",attrs:{"label":"24h总超滤量(ml)","rules":[
              function (v) { return !!v ||
                _vm.rule('FILTRATIONCAPACITY') ||
                '请填写24h总超滤量(ml)！'; } ],"type":"number"},model:{value:(_vm.baseInfo.FILTRATIONCAPACITY),callback:function ($$v) {_vm.$set(_vm.baseInfo, "FILTRATIONCAPACITY", $$v)},expression:"baseInfo.FILTRATIONCAPACITY"}})],1):_vm._e(),_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{ref:"URINEVOLUME",attrs:{"label":"24h总尿量(ml)","rules":[
              function (v) { return !!v || _vm.rule('URINEVOLUME') || '请填写24h总尿量(ml)！'; } ],"type":"number"},model:{value:(_vm.baseInfo.URINEVOLUME),callback:function ($$v) {_vm.$set(_vm.baseInfo, "URINEVOLUME", $$v)},expression:"baseInfo.URINEVOLUME"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{ref:"DRINKINGWATER",attrs:{"label":"24h总饮水量(ml)","rules":[
              function (v) { return !!v || _vm.rule('DRINKINGWATER') || '请填写24h总饮水量(ml)！'; } ],"type":"number"},model:{value:(_vm.baseInfo.DRINKINGWATER),callback:function ($$v) {_vm.$set(_vm.baseInfo, "DRINKINGWATER", $$v)},expression:"baseInfo.DRINKINGWATER"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-select',{ref:"ISEDEMA",attrs:{"items":['是', '否'],"label":"是否水肿","rules":[function (v) { return !!v || _vm.rule('ISEDEMA') || '请填写是否水肿！'; }]},model:{value:(_vm.baseInfo.ISEDEMA),callback:function ($$v) {_vm.$set(_vm.baseInfo, "ISEDEMA", $$v)},expression:"baseInfo.ISEDEMA"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{ref:"CBZ",attrs:{"label":"备注"},model:{value:(_vm.baseInfo.CBZ),callback:function ($$v) {_vm.$set(_vm.baseInfo, "CBZ", $$v)},expression:"baseInfo.CBZ"}})],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","loading":_vm.loading},on:{"click":_vm.commit}},[_vm._v(" 提交 ")])],1)],1),(false)?_c('PreCheck'):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }