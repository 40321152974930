<template>
  <div
    class="pt-12 grey lighten-3"
    style="height:calc(100% - 50px);overflow:auto"
    ref="card"
  >
    <v-card class="ma-4 px-4 py-4">
      <v-sheet
        color="primary"
        width="100%"
        elevation="6"
        class="px-4 py-2 v-card-material mb-n6 rounded"
        dark
      >
        <div class="v-card-material-title pt-1">
          腹膜透析(CAPD/APD)每日录入
        </div>
        <div class="v-card-material-subtitle py-1">
          腹透远程系统
        </div>
      </v-sheet>
      <v-form v-model="valid" ref="form">
        <v-row no-gutters dense>
          <v-col cols="12">
            <v-chip
              class="elevation-2"
              color="primary"
              label
              text-color="white"
            >
              <v-icon left>
                mdi-label
              </v-icon>
              {{ baseInfo.ISCAPD }}
            </v-chip>
          </v-col>

          <v-col cols="12">
            <v-radio-group class="mt-4" v-model="baseInfo.ISCAPD" row>
              <template v-slot:label>
                <div class="m_black">每日录入</div>
              </template>
              <v-radio label="CAPD" value="CAPD" />
              <v-radio label="APD" value="APD" />
            </v-radio-group>
          </v-col>
          <v-col cols="12">
            <v-text-field
              ref="WEIGHT"
              v-model="baseInfo.WEIGHT"
              label="体重(Kg)"
              :rules="[(v) => !!v || rule('WEIGHT') || '请填写体重(Kg)！']"
              type="number"
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field
              ref="BLOODPRESSURE"
              v-model="baseInfo.BLOODPRESSURE"
              label="收缩压(mmHg)"
              :rules="[
                (v) => !!v || rule('BLOODPRESSURE') || '请填写收缩压(mmHg)！',
              ]"
              type="number"
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field
              ref="DIASTOLICPRESSURE"
              v-model="baseInfo.DIASTOLICPRESSURE"
              label="舒张压(mmHg)"
              :rules="[
                (v) =>
                  !!v || rule('DIASTOLICPRESSURE') || '请填写舒张压(mmHg)！',
              ]"
              type="number"
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field
              ref="CAPD15NUMBERBAGS"
              v-model="baseInfo.CAPD15NUMBERBAGS"
              label="1.5%腹透液袋数"
              :rules="[
                (v) =>
                  !!v || rule('CAPD15NUMBERBAGS') || '请填写1.5%腹透液袋数！',
              ]"
              type="number"
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field
              ref="CAPD25NUMBERBAGS"
              v-model="baseInfo.CAPD25NUMBERBAGS"
              label="2.5%腹透液袋数"
              :rules="[
                (v) =>
                  !!v || rule('CAPD25NUMBERBAGS') || '请填写2.5%腹透液袋数！',
              ]"
              type="number"
            ></v-text-field>
          </v-col>
          <v-col cols="12" v-if="baseInfo.ISCAPD === 'CAPD'">
            <v-text-field
              ref="FILTRATIONCAPACITY"
              v-model="baseInfo.FILTRATIONCAPACITY"
              label="24h总超滤量(ml)"
              :rules="[
                (v) =>
                  !!v ||
                  rule('FILTRATIONCAPACITY') ||
                  '请填写24h总超滤量(ml)！',
              ]"
              type="number"
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field
              ref="URINEVOLUME"
              v-model="baseInfo.URINEVOLUME"
              label="24h总尿量(ml)"
              :rules="[
                (v) => !!v || rule('URINEVOLUME') || '请填写24h总尿量(ml)！',
              ]"
              type="number"
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field
              ref="DRINKINGWATER"
              v-model="baseInfo.DRINKINGWATER"
              label="24h总饮水量(ml)"
              :rules="[
                (v) =>
                  !!v || rule('DRINKINGWATER') || '请填写24h总饮水量(ml)！',
              ]"
              type="number"
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-select
              ref="ISEDEMA"
              :items="['是', '否']"
              v-model="baseInfo.ISEDEMA"
              label="是否水肿"
              :rules="[(v) => !!v || rule('ISEDEMA') || '请填写是否水肿！']"
            />
          </v-col>
          <v-col cols="12">
            <v-text-field
              ref="CBZ"
              v-model="baseInfo.CBZ"
              label="备注"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-form>
      <v-card-actions>
        <v-spacer />
        <v-btn color="primary" @click="commit" :loading="loading">
          提交
        </v-btn>
      </v-card-actions>
    </v-card>
    <PreCheck v-if="false" />
  </div>
</template>
<script>
import { mapState } from "vuex";
import PreCheck from "@/components/Local/PreCheck.vue";
export default {
  name: "CAPDAPD",
  components: { PreCheck },
  data: () => ({
    loading: false,
    baseInfo: {
      ISCAPD: "CAPD",
    },
    valid: false,
    rulePostion: true,
  }),
  computed: {
    ...mapState(["openId", "currentUser"]),
  },
  methods: {
    rule(target) {
      if (this.rulePostion && this.$refs[target]) {
        let temp = this.$refs[target];
        if (this.$refs[target].constructor == Array) {
          temp = this.$refs[target][0];
        }
        this.$vuetify.goTo(temp, {
          duration: 300,
          offset: 0,
          easing: "easeInOutCubic",
          container: this.$refs.card,
        });
        this.rulePostion = false;
      }
      return false;
    },
    commit() {
      if (!this.valid) {
        this.$refs.form.validate();
        this.rulePostion = true;
      } else {
        this.save();
      }
    },
    save() {
      this.loading = true;
      this.$api
        .postAdd_CAPD({
          ...this.baseInfo,
          IDCARD: this.currentUser.IDCARD,
          PNAME: this.currentUser.PNAME,
          PSEX: this.currentUser.PSEX,
          PAGE: this.currentUser.PAGE,
        })
        .then((res) => {
          let data = JSON.parse(res);
          if (data.code !== 0) {
            this.$notify.error({
              message: data.message,
            });
            return;
          } else {
            this.$notify.success({
              message: data.message,
            });
          }
        })
        .catch((error) => {
          this.$notify.error({
            message: error,
          });
          console.error(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
<style scoped>
.v-card-material {
  position: relative;
  top: -40px;
  transition: 0.3s ease;
  z-index: 1;
}
.v-card-material-title {
  font-size: 1.125rem;
}
.v-card-material-subtitle {
  font-size: 0.875rem;
  color: #ffffffaa;
}
.m_black {
  font-size: 16px;
  color: #000000a0;
}
.bg_4 {
  background: linear-gradient(87deg, #2dce89, #2dcecc) !important;
}
</style>
